@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input:is(:-webkit-autofill, :autofill) {
    -webkit-text-fill-color: #000;
    -webkit-background-clip: text;
    box-shadow: 0 0 0 50px #fff inset;
  }

  :root {
    --background: 12, 100%, 99%, 1;
    --foreground: 204, 2%, 40%, 1;
    --text-primary: 0, 0%, 7%, 1;
    --text-secondary: 0, 0%, 12%, 1;
    --card: 216, 33%, 97%, 1;
    --card-foreground: 222.2 84% 4.9%;
    --card-secondary: 216, 33%, 97%, 0.5;

    --card-border-blue: 218, 38%, 67%, 1;
    --card-border-green: 148, 89%, 33%, 1;

    --red-40: 5, 74%, 54%, 1;
    --red-main: 0, 94%, 65%, 1;
    --indicator-red: 5, 74%, 54%, 0.2;
    --indicator-border-green: 142, 88%, 32%, 1;
    --indicator-green: 142, 88%, 32%, 0.2;
    --indicator-border-yellow: 33, 80%, 40%, 1;
    --indicator-yellow: 33, 80%, 40%, 0.2;
    --indicator-gray: 204, 2%, 40%, 0.2;

    --stat-yellow: 52, 84%, 48%, 1;
    --stat-ligth-green: 85, 82%, 54%, 1;
    --stat-green: 148, 89%, 33%, 1;

    --radar-green: 148, 89%, 33%, 1;
    --radar-light-green: 85, 82%, 54%, 0.59;
    --radar-yellow: 52, 85%, 48%, 1;
    --radar-red: 5, 74%, 54%, 1;

    --shadow-blue: 205, 42%, 91%, 1;
    --shadow-green: 148, 89%, 33%, 0.2;
    --shadow-red: 5, 74%, 54%, 0.2;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --main-action-button: 172, 92%, 10%, 1;
    --main-blue: 221, 83%, 53%, 1;
    --blue-light: 221, 83%, 91%, 1;
    --blue-10: 216, 60%, 95%, 1;

    --mint: 163, 41%, 91%, 1;
    --green: 141, 38%, 45%, 1;

    --dialog-footer: 0, 0%, 87%, 0.2;
    --overlay: 0, 0%, 41%, 0.6;

    --black: 0, 100%, 0%, 1;
    --black-20: 0, 0%, 93%, 1;
    --black-40: 240, 1%, 80%, 1;
    --black-50: 0, 0%, 87%;
    --black-60: 0, 0%, 69%, 1;
    --black-70: 204, 2%, 40%, 1;
    --black-100: 0, 0%, 7%;

    --main-pink: 342, 92%, 71%;

    --light-gray: 0, 0%, 98%, 1;
    --cyan-gray: 180, 9%, 91%, 1;

    --border: 205, 42%, 91%;
    --border-secondary: 0, 0%, 87%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 1rem;
    --chart-1: 120, 71%, 44%, 1;
    --chart-2: 40, 98%, 52%, 1;
    --chart-3: 359, 71%, 44%, 1;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
